import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageHeader from "components/StaticPageHeader";
import RegulationsContent from "components/RegulationsContent";

const CookiesPolicy = ({ data }) => {
  const content = data?.cookiesPolicy?.content;

  const seo = data?.cookiesPolicy?.seo;
  const schema = JSON.parse(data?.cookiesPolicy?.seo?.schema?.raw);

  return (
    <Layout
      location="Polityka cookies"
      seo={{ title: seo?.title, description: seo?.metaDesc, schema }}
      blue_header
    >
      <StaticPageHeader title="Polityka cookies" />
      <RegulationsContent data={content} />
    </Layout>
  );
};

export default CookiesPolicy;

export const query = graphql`
  query cookiesPolicy {
    cookiesPolicy: wpPage(id: { eq: "cG9zdDoxMjAy" }) {
      content
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
